<template>
  <div class="wapp">
    <a href="https://wa.me/31641079463">
      <i class="fa fa-whatsapp fa-3x my-float"></i>
    </a>
  </div>
</template>

<script>
export default {
  name: "Whatsapp-"
}
</script>

<style scoped>
.wapp{
  width: 60px;
  height: 60px;
  z-index: 100;
  position: fixed;
  bottom:40px;
  right:40px;
  background-color: #4CAF50 !important;
  border-radius:50px;
  text-align:center;
  box-shadow: 2px 2px 3px #999;
  color: #ffffff;
  display: none;
}
.my-float{
  margin-top:7px;
  margin-left: 2px;
}
@media screen and (max-width: 900px) {
  .wapp {
    display: block;
  }
}
</style>