<template>
  <a :href="link" v-on:click="$emit('close')" class="w3-bar-item w3-button w3-wide"> <i :class="i !== undefined ? i : ''"></i> {{name}}</a>
</template>

<script>
export default {
  name: "NavElement",
  props: ['name', 'link', 'i']
}
</script>

<style scoped>

</style>