<template>
  <div class="w3-container" style="padding:128px 16px" id="about">
    <h3 class="w3-center" style="color: green">OVER KATALYSATOR RECYCLING TWENTE</h3>
    <p class="w3-center w3-large" style="color: green">Waar wij bekend om staan
    </p>
    <div class="w3-row-padding w3-center" style="margin-top:64px">
      <div class="w3-quarter">
        <i class="fa fa-eur w3-margin-bottom w3-jumbo" style="color: green"></i>
        <p style="color: green">Bij Katalysator recycling Twente krijgt u een eerlijke prijs gebaseerd op de juiste dagprijs</p>
      </div>
      <div class="w3-quarter">
        <i class="fa fa-truck w3-margin-bottom w3-jumbo" style="color: green"></i>
        <p style="color: green">U kunt uw oude katalysatoren brengen of op laten halen</p>
      </div>
      <div class="w3-quarter">
        <i class="fa fa-automobile w3-margin-bottom w3-jumbo w3-center" style="color: green"></i>
        <p style="color: green">Wij accepteren Katalysatoren en Roetfilters</p>
      </div>
      <div class="w3-quarter">
        <i class="fa fa-euro w3-margin-bottom w3-jumbo" style="color: green"></i>
        <p style="color: green">Bij Katalysator recycling Twente is contant of bank mogelijk </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About-"
}
</script>

<style scoped>

</style>