<template>
  <header class="bgimg-1 w3-display-container" id="home">
    <div class="w3-display-left w3-text-black w3-row bgLabel w3-center w3-green" style="padding:48px;">
      <span class="w3-jumbo w3-hide-small"><i class="fa fa-recycle"></i> Katalysator Recycling Twente <i class="fa fa-recycle"></i></span><br>
      <span class="w3-xxlarge w3-hide-large w3-hide-medium"><i class="fa fa-recycle"></i> Katalysator recycling Twente <i class="fa fa-recycle"></i></span><br>
      <span class="w3-large">Inkoop van elke Katalysator en Roetfilter voor de juiste prijs</span>
      <p><a href="#about"
            class=" btnWidth w3-button w3-white w3-padding-large w3-large w3-margin-top w3-opacity w3-hover-opacity-off">Over ons</a><a
          href="#contact"
          class=" btnWidth w3-button w3-white w3-padding-large w3-large w3-margin-top w3-opacity w3-hover-opacity-off">Contact</a>
      </p>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header-"
}
</script>

<style scoped>
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");

.bgimg-1 {
  background-position: center;
  background-size: cover;
  background-image: url("../assets/background.jpeg");
  height: 1000px;
}

.bgLabel {
  width: 60%;
  margin-right: 20%;
  margin-left: 20%;
}

@media only screen and (max-width: 600px) {
  .btnWidth {
    width: 100%;
  }

  .w3-display-left{
    top: 25%
  }
}
</style>
