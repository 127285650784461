<template>
  <!-- Contact Section -->
  <div class="w3-container w3-light-grey" style="padding:128px 16px" id="contact">
    <h3 class="w3-center">CONTACT</h3>
    <p class="w3-center w3-large">Voor het maken van een afspraak kunt u ons altijd Whatsappen</p>
    <div style="margin-top:48px; display: inline-block;" >
      <p style="text-align: left"><i class="fa fa-id-card-al fa-fw w3-xxlarge w3-margin-right"></i><b> Martijn Revoort</b></p>
      <p style="text-align: left"><i class="fa fa-phone fa-fw w3-xxlarge w3-margin-right"></i> <a href="https://wa.me/31641079463">+31 (0)641079463</a></p>

      <p style="text-align: left"><i class="fa fa-envelope fa-fw w3-xxlarge w3-margin-right"></i> <a href="mailto:krtwente@gmail.com">krtwente@hotmail.com</a></p>
      <p style="text-align: left"><i class="fa fa-map-marker fa-fw w3-xxlarge w3-margin-right"></i> Twentepoort Oost, Almelo</p>

    </div>

  </div>

</template>

<script>
export default {
  name: "Contact-"
}
</script>

<style scoped>
.lefite{
  margin-left: 80px;
}

@media screen and (max-width: 900px) {
  .lefite {
    margin-left: 0;
  }
}
</style>
