<template>
  <div class="w3-top">
    <div class="w3-bar w3-white w3-card" id="myNavbar">
      <NavElement v-bind:name="'DIRECT CONTACT? +31(0)641079463'" v-bind:link="'tel:+310641079463'"></NavElement>
      <!-- Right-sided navbar links -->
      <div class="w3-right w3-hide-small">

        <NavElement v-for="item in this.links" v-bind:key="item.link" v-bind:name="item.name"
                    v-bind:link="item.link"></NavElement>
      </div>
      <!-- Hide right-floated links on small screens and replace them with a menu icon -->

      <div class="w3-bar-item w3-button w3-right w3-hide-large w3-hide-medium" v-on:click="this.w3_open">
        <i class="fa fa-bars"></i>
      </div>

      <nav class="w3-sidebar w3-bar-block w3-black w3-card w3-animate-left w3-hide-medium w3-hide-large"
           style="display:none" id="mySidebar" v-on:click="close">
        <div href="javascript:void(0)" v-on:click="this.close"
             class="w3-bar-item w3-button w3-large w3-padding-16">Close
          ×
        </div>
        <div v-for="item in this.links" v-bind:key="item.link">
          <NavElement v-bind:name="item.name" v-bind:link="item.link"></NavElement>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import NavElement from "@/components/NavElement";

export default {
  name: "NavBar",
  data() {
    return {
      "links": [
        {
          "name": 'OVER ONS',
          "link": '#about',
          "i": ''
        }, {
          "name": 'CONTACT',
          "link": '#contact',
          "i": 'fa fa-envelope'
        }
      ]
    }
  },
  components: {NavElement},
  methods: {
    close() {
      let mySidebar = document.getElementById("mySidebar");
      mySidebar.style.display = "none";
    }, w3_open() {
      let mySidebar = document.getElementById("mySidebar");
      if (mySidebar.style.display === 'block') {
        mySidebar.style.display = 'none';
      } else {
        mySidebar.style.display = 'block';
      }
    }
  }
}
</script>

<style scoped>

</style>