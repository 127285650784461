<template>
  <div id="app">
    <whatsapp/>
    <NavBar/>
    <Header/>
    <About/>
    <Contact/>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import About from "@/components/About";
import Header from "@/components/Header";
import Contact from "@/components/Contact";
import Whatsapp from "./components/Whatsapp";

export default {
  name: 'App',
  data(){
    return {
    }
  },
  components: {
    Whatsapp,
    Contact,
    Header,
    About,
    NavBar
  }, methods: {
    onClick(element) {
      document.getElementById("img01").src = element.src;
      document.getElementById("modal01").style.display = "block";
      var captionText = document.getElementById("caption");
      captionText.innerHTML = element.alt;
    }

  }
}
</script>

<style>
@import url("https://www.w3schools.com/w3css/4/w3.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
@import url("https://www.w3schools.com/lib/w3-theme-deep-orange.css");

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

body, html {
  height: 100%;
  line-height: 1.8;
}


</style>
